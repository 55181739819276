<template>
  <!--begin::List Widget 9-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header align-items-center border-0 mt-4">
      <h3 class="card-title align-items-start flex-column">
        <span class="font-weight-bolder text-dark">
          Jadwal Perjanjian Hari Ini
        </span>
        <span class="text-muted mt-3 font-weight-bold font-size-sm">
          {{dataLength}} Perjanjian
        </span>
      </h3>
      <!-- <div class="card-toolbar">
        <Dropdown2><i class="ki ki-bold-more-hor"></i></Dropdown2>
      </div> -->
    </div>
    <!--end::Header-->

    <!--begin::Body-->
    <div class="card-body pt-4">
      <div class="timeline timeline-6 mt-3">
        <template v-for="(item, i) in list">
          <!--begin::Item-->
          <div class="timeline-item align-items-start" v-bind:key="i">
            <!--begin::Label-->
            <div
              class="timeline-label font-weight-bolder text-dark-75 font-size-lg"
            >
              {{ item.display_time}}
            </div>
            <!--end::Label-->

            <!--begin::Badge-->
            <div class="timeline-badge">
              <i class="icon-xl fa fa-genderless text-success"></i>
            </div>
            <!--end::Badge-->

            <!--begin::Text-->
            <div
              class="font-weight-mormal font-size-lg timeline-content text-muted pl-3"
            >
              <span
                v-bind:class="{
                  'font-weight-bolder text-dark-75': true,
                }"
                class="d-block"
              >{{item.doctor_name + " - " + item.action_type_name}}</span>
              <span class="text-info">{{item.display_patient_name}}</span>
              <!-- <span
                v-bind:class="{
                  'font-weight-bolder text-dark-75': true,
                  'mr-4': item.images
                }"
                v-html="item.desc"
              ></span> -->
            </div>
            <!--end::Text-->
          </div>
          <!--end::Item-->
        </template>
      </div>
      <!--end: Items-->
       <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        @page-click="pageOnClick"
        class="mt-4"
        v-if="showPaging"
      ></b-pagination>
    </div>
    <!--end: Card Body-->
  </div>
  <!--end: Card-->
  <!--end: List Widget 9-->
</template>

<script>
import ApiService from "@/core/services/api.service.js";
import Dropdown2 from "@/view/content/dropdown/Dropdown2.vue";
import { mapGetters } from "vuex";
import module from "@/core/modules/CrudModule.js";
export default {
  name: "widget-9",
  data() {
    return {
      list: [],
      dataLength:0,
     // Pagination
      perPage: 7,
      currentPage: 1,
      totalRows: 1,
      showPaging:false,
    };
  },
  components: {
    Dropdown2
  },
  methods: {
    // async pagination() {
    //   let filterParams = `&name=${this.filter.name}&birt_date=${this.filter.birt_date}`
    //   let response = await module.paginate('patients', `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`)
    //   let pagination = response.meta.pagination
    //   this.items = response.data
    //   this.totalRows = pagination.total
    // },
    async getData(){
      // let response = await module.get('total-appointments-today')
      let resPaginate = await module.paginate('appointments-today/today', `?page=${this.currentPage}&page_size=${this.perPage}`)
      // this.list = response.data
      ApiService.get("total-appointments-today").then((response) => {
        if (response.length != 0) {
        this.dataLength = response.data
        } else {
          this.dataLength = 0
        }
      });
      this.totalRows = resPaginate.meta.pagination.total
      this.list = resPaginate.data
      console.log( this.totalRows,"length");
      // show or hide paging 
      if( parseInt(this.totalRows) > 7){
        this.showPaging = true
      }


      // console.log("data responnnnnn",response.data);
      
    },
     async pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      await this.getData();
    },
    lastElement(i) {
      
      if (i === this.list.length - 1) {
        return false;
      } else {
        return true;
      }
    }
  },
  computed: {
    ...mapGetters(["layoutConfig"])
  },
  mounted(){
    this.getData()
  }
};
</script>
