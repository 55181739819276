<template>
  <!--begin::List Widget 1-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">
          Dokter Praktik Hari Ini
        </span>
        <span class="text-muted mt-3 font-weight-bold font-size-sm">
          {{dataLength}} Dokter Berpraktik Hari Ini
        </span>
      </h3>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-2">
      <template v-for="(item, i) in list">
        <!--begin::Item-->
        <div class="d-flex align-items-center mb-10" v-bind:key="i">
          <!--begin::Symbol-->
          <div
            class="symbol symbol-40 mr-5"
          >
            <span class="symbol-label">
            
                <!--begin::Svg Icon-->
                 <img
                    :src="item.photo ? item.photo : '/images/doctor.jpeg' "
                    class="h-100 align-self-center"
                    alt=""
                  />

                  
                  
                <!--end::Svg Icon-->
            </span>
          </div>
          <!--end::Symbol-->
          <!--begin::Text-->
          <div class="d-flex flex-column font-weight-bold">
            <a class="text-dark text-hover-primary mb-1 font-size-lg">
              {{ item.doctor_name }}
            </a>
            <span class="text-muted">
              {{ item.policlinic_name +" - "+ item.mobile_phone }}
            </span>
          </div>
          <!--end::Text-->
        </div>
        <!--end::Item-->
      </template>
      <b-pagination
        
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        @page-click="pageOnClick"
        class="mt-4"
        v-if="showPaging"
      ></b-pagination>
    </div>
    <!--end::Body-->
  </div>
  <!--end: List Widget 1-->
</template>

<script>
import Dropdown3 from "@/view/content/dropdown/Dropdown3.vue";
import { mapGetters } from "vuex";
import module from "@/core/modules/CrudModule.js";

export default {
  name: "widget-1",
  data() {
    return {
      list: [],
      dataLength:0,
      // Pagination
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      showPaging:false
    };
  },
  methods:{
    async getData(){
      let response = await module.get('schedules-length-today')
      let resPaginate = await module.paginate('schedules-today', `?page=${this.currentPage}&page_size=${this.perPage}`)

      this.list = resPaginate.data
      this.totalRows = resPaginate.meta.pagination.total
      this.dataLength = response.length

      if(this.totalRows > 10){
        this.showPaging = true
      }
      
    },
    
     async pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      await this.getData();
    },
  },
  components: {
    Dropdown3
  },
  computed: {
    
    ...mapGetters(["layoutConfig"])
  },
  mounted(){
    this.getData()
  }
};
</script>
