<template>
  <!--begin::Mixed Widget 1-->
  <div class="card card-custom bg-gray-100 card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 bg-danger py-5">
      <h3 class="card-title font-weight-bolder text-white">
        Statistik Bulan Ini
      </h3>
      <!-- <div class="card-toolbar">
        <Dropdown1></Dropdown1>
      </div> -->
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body p-0 position-relative overflow-hidden">
      <!--begin::Chart-->
      <!-- <apexchart
        class="card-rounded-bottom bg-danger pb-5"
        :options="chartOptions"
        :series="series"
        type="area"
        v-if="chartLoad == true"
      ></apexchart> -->
      <!--end::Chart-->
      <!--begin::Stats-->
      <div class="card-spacer mt-n25 mt-5">
        <!--begin::Row-->
        <div class="row m-0">
          <!-- <div class="col bg-light-warning px-6 py-8 rounded-xl mr-7 mb-7"> -->
          <div class="col bg-light-warning col-12 rounded-xl">
            <span class="svg-icon svg-icon-3x svg-icon-warning d-block my-2 d-flex justify-content-center">
              <inline-svg src="media/svg/icons/Media/Equalizer.svg" />
              <span class="text-warning font-weight-bold font-size-h1 ">
                <!-- invoice rumah sakit -->
                {{data.income}}
              </span>
            </span>
            <span class="text-warning font-weight-bold font-size-h6 mt-3 d-flex justify-content-center">
              Invoice Rumah Sakit
            </span>
            
          </div>
          <!-- <div class="col bg-light-primary px-6 py-8 rounded-xl mb-7"> -->
          <div class="col bg-light-primary col-12 mt-4 rounded-xl">
            <span class="svg-icon svg-icon-3x svg-icon-primary d-block my-2 d-flex justify-content-center">
              <inline-svg src="media/svg/icons/Communication/Add-user.svg" />
              <span class="text-primary font-weight-bold font-size-h1 ">
                <!-- latest patient rumah sakit -->
                {{data.patient}}
              </span>
            </span>
            <span  class="text-primary font-weight-bold font-size-h6 mt-2 d-flex justify-content-center">
              Pasien Baru
            </span>
          </div>
        </div>
        <!--end::Row-->
        <!--begin::Row-->
        <div class="row m-0">
          <!-- <div class="col bg-light-danger px-6 py-8 rounded-xl mr-7"> -->
          <div class="col-12 bg-light-danger mt-4 rounded-xl">
            <span class="svg-icon svg-icon-3x svg-icon-danger d-block my-2 d-flex justify-content-center">
              <inline-svg src="media/svg/icons/Design/Layers.svg" />
              <span class="text-danger font-weight-bold font-size-h1 ">
                <!-- latest patient rumah sakit -->
                {{data.medicineOrder}}
              </span>
            </span>
            <span  class="text-danger font-weight-bold font-size-h6 mt-2 d-flex justify-content-center">
              Transaksi Apotik
            </span>
          </div>
          <!-- <div class="col bg-light-success px-6 py-8 rounded-xl"> -->
          <div class="col-12 bg-light-success mt-4 rounded-xl">
            <span class="svg-icon svg-icon-3x svg-icon-success d-block my-2 d-flex justify-content-center">
              <inline-svg src="media/svg/icons/Communication/Urgent-mail.svg" />
              <span class="text-success font-weight-bold font-size-h1 ">
                <!-- latest patient rumah sakit -->
                {{data.appointment}}
              </span>
            </span>
            <span  class="text-success font-weight-bold font-size-h6 mt-2 d-flex justify-content-center">
              Perjanjian
            </span>
          </div>
        </div>
        <!--end::Row-->
      </div>
      <!--end::Stats-->
      <!--end::Body-->
    </div>
    <!--end::Mixed Widget 1-->
  </div>
</template>

<script>
import Dropdown1 from "@/view/content/dropdown/Dropdown1.vue";
import { mapGetters } from "vuex";
import module from "@/core/modules/CrudModule.js";
import moment from 'moment'

export default {
  name: "widget-1",
  components: {
    Dropdown1
  },
  data() {
    return {
      strokeColor: "#D13647",
      chartOptions: {},
      series: [
        {
          name: "Jumlah ",
          data: []
        }
      ],
      data:{
        income:"",
        patient:"",
        medicineOrder:"",
        appointment:""

      },
      chartLoad:false,
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"])
  },
  mounted() {
    this.getData()
    // reference; kt_mixed_widget_1_chart
    // this.chartOptions = {
    //   chart: {
    //     type: "area",
    //     height: 200,
    //     toolbar: {
    //       show: false
    //     },
    //     zoom: {
    //       enabled: false
    //     },
    //     sparkline: {
    //       enabled: true
    //     },
    //     dropShadow: {
    //       enabled: true,
    //       enabledOnSeries: undefined,
    //       top: 5,
    //       left: 0,
    //       blur: 3,
    //       color: this.strokeColor,
    //       opacity: 0.5
    //     }
    //   },
    //   plotOptions: {},
    //   legend: {
    //     show: false
    //   },
    //   dataLabels: {
    //     enabled: false
    //   },
    //   fill: {
    //     type: "solid",
    //     opacity: 0
    //   },
    //   stroke: {
    //     curve: "smooth",
    //     show: true,
    //     width: 3,
    //     colors: [this.strokeColor]
    //   },
    //   xaxis: {
    //     categories: ["Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug"],
    //     axisBorder: {
    //       show: false
    //     },
    //     axisTicks: {
    //       show: false
    //     },
    //     tooltip: {
    //       enabled: false
    //     },
    //     labels: {
    //       show: false,
    //       style: {
    //         colors: this.layoutConfig("colors.gray.gray-500"),
    //         fontSize: "12px",
    //         fontFamily: this.layoutConfig("font-family")
    //       }
    //     },
    //     crosshairs: {
    //       show: false,
    //       position: "front",
    //       stroke: {
    //         color: this.layoutConfig("colors.gray.gray-300"),
    //         width: 1,
    //         dashArray: 3
    //       }
    //     }
    //   },
    //   yaxis: {
    //     show: false,
    //     min: 0,
    //     max: 80,
    //     labels: {
    //       show: false,
    //       style: {
    //         colors: this.layoutConfig("colors.gray.gray-500"),
    //         fontSize: "12px",
    //         fontFamily: this.layoutConfig("font-family")
    //       }
    //     }
    //   },
    //   states: {
    //     normal: {
    //       filter: {
    //         type: "none",
    //         value: 0
    //       }
    //     },
    //     hover: {
    //       filter: {
    //         type: "none",
    //         value: 0
    //       }
    //     },
    //     active: {
    //       allowMultipleDataPointsSelection: false,
    //       filter: {
    //         type: "none",
    //         value: 0
    //       }
    //     }
    //   },
    //   tooltip: {
    //     style: {
    //       fontSize: "12px",
    //       fontFamily: this.layoutConfig("font-family")
    //     },
    //     y: {
    //       formatter: function(val) {
    //         return "$" + val + " thousands";
    //       }
    //     },
    //     marker: {
    //       show: false
    //     }
    //   },
    //   colors: ["transparent"],
    //   markers: {
    //     colors: [this.layoutConfig("colors.theme.light.danger")],
    //     strokeColor: [this.strokeColor],
    //     strokeWidth: 3
    //   },
    //   grid: {
    //     show: false,
    //     padding: {
    //       left: 0,
    //       right: 0
    //     }
    //   }
    // };
  },
  methods:{
    async getData(){
      //let response = await module.paginate('payments', `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`)
      
      let income = await module.get('payments-dashboard')
      let patient = await module.get('patients-dashboard')
      let pharmacyOrder = await module.get('pharmacy-orders-dashboard')
      let appointments = await module.get('appointments-dashboard')

      // let dataRes = await module.get('payments-month-graphic')
      

      this.data.income = income.length
      this.data.patient = patient.length
      this.data.medicineOrder = pharmacyOrder.length
      this.data.appointment = appointments.length

      let a,c,b
      let e =0 
      let datas = []
      let dataArray = []

      for(c= 0; c< dataRes.length;c++){
        datas.push(dataRes[c])
      }

      for (a = 0; a < dataRes.length; a++) {
        for(b=0;b<datas.length;b++){
          if(dataRes[a].display_created_at == datas[b].display_created_at){
            if(dataRes[a].feature != datas[b].feature){
              dataRes[a].final_amount = parseInt(dataRes[a].final_amount) + parseInt(datas[b].final_amount)
              dataRes[a] = dataRes[a]

              var index = dataRes.map(x => {
                return x.id
              }).indexOf(datas[b].id)

              dataRes.splice(index,1)
            }
          }
        }
      }
      
      
      

      let g = 0
      let h
       let arr = []
      
      
      for (h = 0; h < moment().date(); h++) {
      let dateCategory = moment().subtract(moment().date()-1,'d').add('days',h).format('DD-MM-YYYY')

      if(dataRes.length != 0){
       var value = dataRes[g].final_amount
        var name = dataRes[g].display_created_at
        
        
        
        if (dateCategory == name) {
          var arrSuccess = {
            "name": name,
            "value": value
          }
          arr.push(arrSuccess)
          if (g < dataRes.length - 1) {
            g++
          }

        } else {
          var arrFail = {
            "name": dateCategory,
            "value": 0
          }
          arr.push(arrFail)
          
        }
        // if (b < response.length - 1) {
        //     b++
        // }
      }else{
        var arrFail = {
          "name": dateCategory,
          "value": 0
        }
        arr.push(arrFail)
         
      }
      }
       
        this.series[0].data = arr.map(arr => parseFloat(arr.value))
        window.arrData = arr.map(arr => String(arr.name))
        this.chartLoad = true

        this.chartOptions = {
      chart: {
        type: "area",
        height: 100,
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        },
        sparkline: {
          enabled: true
        },
        dropShadow: {
          enabled: true,
          enabledOnSeries: undefined,
          top: 5,
          left: 0,
          blur: 3,
          color: this.strokeColor,
          opacity: 0.5
        }
      },
      plotOptions: {},
      legend: {
        show: false
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        type: "solid",
        opacity: 0
      },
      stroke: {
        curve: "smooth",
        show: true,
        width: 3,
        colors: [this.strokeColor]
      },
      xaxis: {
        categories: window.arrData,
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        tooltip: {
          enabled: false
        },
        labels: {
          show: false,
          style: {
            colors: this.layoutConfig("colors.gray.gray-500"),
            fontSize: "12px",
            fontFamily: this.layoutConfig("font-family")
          }
        },
        crosshairs: {
          show: false,
          position: "front",
          stroke: {
            color: this.layoutConfig("colors.gray.gray-300"),
            width: 1,
            dashArray: 3
          }
        }
      },
      yaxis: {
        show: false,
        min: 0,
        labels: {
          show: false,
          style: {
            colors: this.layoutConfig("colors.gray.gray-500"),
            fontSize: "12px",
            fontFamily: this.layoutConfig("font-family")
          }
        }
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0
          }
        },
        hover: {
          filter: {
            type: "none",
            value: 0
          }
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0
          }
        }
      },
      tooltip: {
        style: {
          fontSize: "12px",
          fontFamily: this.layoutConfig("font-family")
        },
        y: {
          formatter: function(val) {
            return "Rp" + parseInt(val).toLocaleString('id-ID') ;
          }
        },
        marker: {
          show: false
        }
      },
      colors: ["transparent"],
      markers: {
        colors: [this.layoutConfig("colors.theme.light.danger")],
        strokeColor: [this.strokeColor],
        strokeWidth: 3
      },
      grid: {
        show: false,
        padding: {
          left: 0,
          right: 0
        }
      }
    };
         
    }
       
      
  },
};
</script>
